import React from 'react'
import { graphql, Link } from 'gatsby'

import {
  VerticalTimeline,
  VerticalTimelineElement,
} from 'react-vertical-timeline-component'
import 'react-vertical-timeline-component/style.min.css'

import Layout from '../components/layout'
import { Col, FormGroup, Input, Row } from 'reactstrap'

class TechnologyJourneyPage extends React.Component {
  constructor(props) {
    super(props)

    // state setup
    this.state = {
      tag: '',
      tags: this.uniqueTags(this.props.data.allMarkdownRemark.edges),
      year: '',
      years: this.uniqueYears(this.props.data.allMarkdownRemark.edges),
      search: '',
    }

    // this binding
    this.changeTag = this.changeTag.bind(this)
    this.changeSearch = this.changeSearch.bind(this)
    this.changeYear = this.changeYear.bind(this)
    this.filterTag = this.filterTag.bind(this)
    this.filterYear = this.filterYear.bind(this)
    this.filterSearch = this.filterSearch.bind(this)
  }
  uniqueTags(edges) {
    let tags = []

    edges.forEach(({ node }) => {
      if (node.frontmatter.tags) {
        node.frontmatter.tags.forEach(tag => {
          // add tag if not already
          if (tags.indexOf(tag) == -1) {
            tags.push(tag)
          }
        })
      }
    })

    return tags
  }
  uniqueYears(edges) {
    let years = []

    edges.forEach(({ node }) => {
      if (node.frontmatter.date) {
        let year = parseInt(node.frontmatter.date.substring(0, 4))
        // add year if not already
        if (years.indexOf(year) == -1) {
          years.push(year)
        }
      }
    })

    // sort by year
    years.sort((a, b) => b - a)

    return years
  }
  styleFor(node) {
    if (node.frontmatter.tags) {
      let firstTag = node.frontmatter.tags[0]

      switch (firstTag) {
        case 'language':
          return { background: '#7c59f3', color: '#fff' }
        case 'tool':
          return { background: '#2196F3', color: '#fff' }
        case 'framework':
          return { background: '#f37904', color: '#fff' }
        case 'protocol':
          return { background: '#046021', color: '#ffffff' }
        default:
          return { background: '#000000', color: '#fff' }
      }
    }
  }
  iconFor(node) {
    if (node.frontmatter.tags) {
      let firstTag = node.frontmatter.tags[0]

      switch (firstTag) {
        case 'language':
          return <i className="fas fa-code" />
        case 'tool':
          return <i className="fas fa-tools" />
        case 'framework':
          return <i className="fas fa-toolbox" />
        case 'protocol':
          return <i className="fas fa-server" />
        default:
          return <i className="fas fa-circle" />
      }
    }
  }
  changeTag(event) {
    this.setState({
      tag: event.target.value,
    })
  }
  changeYear(event) {
    this.setState({
      year: event.target.value,
    })
  }
  changeSearch(event) {
    this.setState({
      search: event.target.value,
    })
  }
  // {} syntax extracts property from parameter object in its own variable , 'node' in this case
  filterTag({ node }) {
    if (this.state.tag != '') {
      // filter on the tags
      if (node.frontmatter.tags.indexOf(this.state.tag) != -1) {
        return true
      }

      return false
    } else {
      // show all
      return true
    }
  }
  filterYear({ node }) {
    if (this.state.year != '') {
      // filter on the year
      if (node.frontmatter.date.indexOf(this.state.year) != -1) {
        return true
      }

      return false
    } else {
      // show all
      return true
    }
  }
  filterSearch({ node }) {
    if (this.state.search != '') {
      // search in title
      if (
        node.frontmatter.title
          .toLowerCase()
          .indexOf(this.state.search.toLowerCase()) != -1
      ) {
        return true
      }

      // search in markdown content
      if (node.rawMarkdownBody.toLowerCase().indexOf(this.state.search.toLowerCase()) != -1) {
        return true
      }

      return false
    } else {
      return true
    }
  }
  render() {
    return (
      <Layout>
        <div className="site-content technology-journey">
          <article>
            <div className="post">
              <h1>{this.props.data.markdownRemark.frontmatter.title}</h1>
              <div
                dangerouslySetInnerHTML={{
                  __html: this.props.data.markdownRemark.html,
                }}
              />
            </div>
          </article>
          <Row>
            <Col lg="8">
              <FormGroup>
                <Input
                  type="text"
                  placeholder="Search"
                  value={this.state.search}
                  onChange={this.changeSearch}
                />
              </FormGroup>
            </Col>
            <Col lg="2" xs="6">
              <FormGroup>
                <Input
                  className="select-filter"
                  type="select"
                  name="tags"
                  value={this.state.tag}
                  onChange={this.changeTag}
                >
                  <option value="">Choose tag</option>
                  {this.state.tags.map(tag => (
                    <option value={tag}>{tag}</option>
                  ))}
                </Input>
              </FormGroup>
            </Col>
            <Col lg="2" xs="6">
              <FormGroup>
                <Input
                  className="select-filter"
                  type="select"
                  name="year"
                  value={this.state.year}
                  onChange={this.changeYear}
                >
                  <option value="">Choose year</option>
                  {this.state.years.map(year => (
                    <option value={year}>{year}</option>
                  ))}
                </Input>
              </FormGroup>
            </Col>
          </Row>
          <hr />
          <VerticalTimeline>
            {this.props.data.allMarkdownRemark.edges
              .filter(this.filterTag)
              .filter(this.filterYear)
              .filter(this.filterSearch)
              .map(({ node }) => (
                <VerticalTimelineElement
                  key={node.id}
                  className="journey-item"
                  iconStyle={this.styleFor(node)}
                  icon={this.iconFor(node)}
                >
                  <h3 className="vertical-timeline-element-title">
                    {node.frontmatter.title}
                  </h3>
                  <div dangerouslySetInnerHTML={{ __html: node.html }} />
                  <hr />
                  <time className="entry-date">
                    {node.frontmatter.dateFormat}
                  </time>
                </VerticalTimelineElement>
              ))}
          </VerticalTimeline>
        </div>
      </Layout>
    )
  }
}

export default TechnologyJourneyPage

export const query = graphql`
  query {
    markdownRemark(frontmatter: { templateKey: { eq: "technologyIntro" } }) {
      html
      frontmatter {
        title
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { templateKey: { eq: "technology" } } }
    ) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            date
            dateFormat: date(formatString: "D MMMM, YYYY")
            tags
          }
          html
          rawMarkdownBody
        }
      }
    }
  }
`
